import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';

import { MENU_ITEMS_ENUM } from '../../../shared/config/menuItems.enum';
import { MRO_ROLES } from '../../../shared/config/roles';
import { RequestsOverviewActions, SetOfferType } from '@libs/request-overview-common/state/requests-overview.actions';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserRoles } from '@libs/shared/models/roles.enum';
import { isEmpty } from 'lodash-es';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  AGENCY_JOB_OPENING,
  FIXED_PRICE_JOB_OPENING,
  JobOpeningFilterType,
  OfferType,
  PERMANENT_JOB_OPENING,
  temporaryJobOpeningFilterGetter
} from '@libs/shared/models/offer.model';
import { getBrandName } from '@libs/shared/bms-common/environment/environment.selector';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdminRequestsOverviewActionTypes, RequestOverviewLinksLoaded } from '../../state/admin-requests-overview.actions';
import { Actions, ofType } from '@ngrx/effects';
import { Link } from '@libs/shared/bms-common/rest/resource.model';
import { MroFacilityLoaderService } from '../../../../../../../../libs/offer-management/shared/services/mro-facility-loader.service';
import { MroFacility } from '@libs/shared/models/facility.model';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { UsersLinkRel } from '@libs/shared/linkrels/users.linkrel';

interface EntityList {
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  uuid: string;
  userUuid: string;
  allowsPackageOffers: boolean;
  allowsPermanentJobOffers: boolean;
}

@UntilDestroy()
@Component({
  selector: 'staffnow-overview-wrapper',
  templateUrl: './overview-wrapper.component.html',
  styleUrls: ['./overview-wrapper.component.scss']
})
export class OverviewWrapperComponent implements OnChanges {
  @Input() activeItem;
  @Input() menuItems: Array<string> = [];
  @Input() entityList: Array<EntityList> = [];
  @Input() isLoading = false;
  @Output() changeRoute: EventEmitter<string> = new EventEmitter<string>();

  menuItemsEnum = MENU_ITEMS_ENUM;
  selectedValueUuid: string = '';
  MRO_ROLES = MRO_ROLES;
  selectedMroRole = UserRoles.ROLE_MRO_PRODUCTION;
  selectedOfferType = OfferType.TEMPORARY;
  entityRetrieverLinks: { _links: { self: Link; [key: string]: Link } };
  protected readonly MENU_ITEMS_ENUM = MENU_ITEMS_ENUM;
  private offerTypes: JobOpeningFilterType[];

  constructor(
    private store: Store<any>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private actions: Actions,
    private mroLoaderService: MroFacilityLoaderService
  ) {
    this.actions
      .pipe(ofType(AdminRequestsOverviewActionTypes.RequestOverviewLinksLoaded), untilDestroyed(this))
      .subscribe((action: RequestOverviewLinksLoaded) => (this.entityRetrieverLinks = action.payload));
    this.watchQueryParams();
  }

  get managingRole(): UserRoles {
    switch (this.activeItem) {
      case MENU_ITEMS_ENUM.TECHNICIANS:
        return UserRoles.ROLE_TECHNICIAN;
      case MENU_ITEMS_ENUM.MROS:
        return this.selectedMroRole;
      case MENU_ITEMS_ENUM.AGENCIES:
        return UserRoles.ROLE_AGENCY;
      default:
        return null;
    }
  }

  get filteredOfferTypes(): JobOpeningFilterType[] {
    return this.offerTypes.filter(it => {
      if (this.isMroSelected()) {
        return it.value !== OfferType.AGENCY;
      } else if (this.isAgencySelected()) {
        return it.value !== OfferType.PERMANENT;
      } else {
        return true;
      }
    });
  }

  getMroListRetriever(): (term: string, pageNumber: number, pageSize: number) => Observable<MroFacility[]> {
    return (term: string, pageNumber: number, pageSize: number) =>
      this.mroLoaderService
        .getMroFacilities(
          getUrl(this.entityRetrieverLinks, UsersLinkRel.GetMROFacilitiesPaged),
          term,
          pageNumber,
          pageSize,
          { sort: '*name' },
          true
        )
        .pipe(map(facilityPage => facilityPage._embedded.facilities));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.activeItem) {
      this.updateActingAs();
    }
    if (Object.hasOwn(changes, 'entityList') && !changes.entityList.firstChange) {
      if (this.entityList && this.entityList.length > 0) {
        this.entitiesListModifier();
      }
    }
  }

  entitiesListModifier(): void {
    if (this.activeItem === MENU_ITEMS_ENUM.TECHNICIANS) {
      this.entityList.map(entity => {
        entity.name =
          entity.firstName === null && entity.lastName === null
            ? `${entity.email}`
            : `${entity.firstName} ${entity.lastName}`;
        entity.uuid = entity.userUuid;
      });
    }
  }

  handleSelectedEntityChange(uuid: string): void {
    this.mergeQueryParam({ uuid });
  }

  onBlurHandler(currentValue: string): void {
    if (currentValue === '') {
      this.store.dispatch(RequestsOverviewActions.FailedToLoadList());
    }
  }

  handleEntityNavigationChange(item: string): void {
    this.changeRoute.emit(item);
  }

  isMroSelected() {
    return this.activeItem === MENU_ITEMS_ENUM.MROS;
  }

  isAgencySelected() {
    return this.activeItem === MENU_ITEMS_ENUM.AGENCIES;
  }

  updateOfferType(): void {
    this.store.dispatch(
      SetOfferType({
        offerType: this.selectedOfferType
      })
    );
    this.mergeQueryParam({ offerType: this.selectedOfferType });
  }

  handleMroRoleChange() {
    this.updateActingAs();
  }

  canOpenCreatePackageOfferPage(): boolean {
    if (this.isMroSelected() && !isEmpty(this.selectedValueUuid)) {
      const selectedMro = this.entityList?.find(it => it.uuid === this.selectedValueUuid);
      return selectedMro ? selectedMro.allowsPackageOffers : false;
    }
    return false;
  }

  canOpenCreatePermanentOfferPage(): boolean {
    if (this.isMroSelected() && !isEmpty(this.selectedValueUuid)) {
      const selectedMro = this.entityList?.find(it => it.uuid === this.selectedValueUuid);
      return selectedMro ? selectedMro.allowsPermanentJobOffers : false;
    }
    return false;
  }

  openCreateJobOfferPage(): void {
    this.router.navigate(['/offer', 'create', this.selectedValueUuid], {
      state: {
        preselectedFilters: {},
        isPrivate: false,
        selectedTechnicians: [],
        isEdit: false,
        breadcrumbs: ['Job Openings and Applications', 'Aviation Companies', 'Create Temporary Job Opening'],
        mroUuid: this.selectedValueUuid
      }
    });
  }

  openCreatePermanentOfferPage(): void {
    this.router.navigate(['/permanent-offer', 'create', this.selectedValueUuid], {
      state: {
        preselectedFilters: {},
        isPrivate: false,
        selectedTechnicians: [],
        isEdit: false,
        breadcrumbs: ['Job Openings and Applications', 'Aviation Companies', 'Create Permanent Job Opening'],
        mroUuid: this.selectedValueUuid
      }
    });
  }

  openCreatePackageOfferPage(): void {
    this.router.navigate(['/package-offer', 'create', this.selectedValueUuid], {
      state: {
        isEdit: false,
        breadcrumbs: ['Job Openings and Applications', 'Aviation Companies', 'Create Fixed-Price Job Opening'],
        mroUuid: this.selectedValueUuid
      }
    });
  }

  private watchQueryParams() {
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(params => this.updateFromParams(params));
  }

  private updateFromParams(params: Params) {
    this.store.pipe(getBrandName, untilDestroyed(this)).subscribe(brandName => {
      const temporaryJobOpeningFilterType = temporaryJobOpeningFilterGetter();
      this.offerTypes = [
        temporaryJobOpeningFilterType,
        PERMANENT_JOB_OPENING,
        FIXED_PRICE_JOB_OPENING,
        AGENCY_JOB_OPENING
      ];
    });
    this.selectedValueUuid = (params.uuid as string) ?? '';
    this.selectedMroRole = (params.role as UserRoles) ?? UserRoles.ROLE_MRO_PRODUCTION;
    this.selectedOfferType = (params.offerType as OfferType) ?? OfferType.TEMPORARY;
    this.store.dispatch(
      RequestsOverviewActions.EntityUuidSet({
        uuid: this.selectedValueUuid
      })
    );
    this.triggerListLoad();
  }

  private updateActingAs() {
    this.store.dispatch(RequestsOverviewActions.SetActingAs({ role: this.managingRole }));
    this.store.dispatch(
      SetOfferType({
        offerType: this.selectedOfferType
      })
    );
    this.mergeQueryParam({
      role: this.managingRole,
      offerType: this.selectedOfferType
    });
  }

  private triggerListLoad() {
    this.store.dispatch(RequestsOverviewActions.LoadListBO());
  }

  private mergeQueryParam(queryParams: Params) {
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams
    });
  }
}
