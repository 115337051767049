import { Action, createAction, props } from '@ngrx/store';
import { Contract } from '@libs/shared/models/contract.model';

export enum AdminRequestsOverviewActionTypes {
  LoadRequestsOverviewLinks = '[AdminRequestsOverview] Load requests overview links',
  RequestOverviewLinksLoaded = '[AdminRequestsOverview] Requests overview links loaded',
  FailedToLoadRequestsOverviewLinks = '[AdminRequestsOverview] Failed to load requests overview links',
  MrosListLoaded = '[AdminRequestsOverview] MROs list loaded',
  LoadAgenciesList = '[AdminRequestsOverview] Load agencies list',
  AgenciesListLoaded = '[AdminRequestsOverview] Agencies list loaded',
  ListLoaded = '[AdminRequestsOverview] List loaded',
  FailedToLoadList = '[AdminRequestsOverview] Failed to load list',
  GetTechnicianUrl = '[AdminRequestsOverview] Load technician registration link'
}

export class MrosListLoaded implements Action {
  public readonly type = AdminRequestsOverviewActionTypes.MrosListLoaded;
  public readonly payload: any = null;

  constructor(payload: any) {
    this.payload = payload;
  }
}

export class LoadAgenciesList implements Action {
  public readonly type = AdminRequestsOverviewActionTypes.LoadAgenciesList;
}

export class AgenciesListLoaded implements Action {
  public readonly type = AdminRequestsOverviewActionTypes.AgenciesListLoaded;
  public readonly payload: any = null;

  constructor(payload: any) {
    this.payload = payload;
  }
}

export class FailedToLoadList implements Action {
  public readonly type = AdminRequestsOverviewActionTypes.FailedToLoadList;
}

export class LoadRequestsOverviewLinks implements Action {
  public readonly type =
    AdminRequestsOverviewActionTypes.LoadRequestsOverviewLinks;
}

export class ListLoaded implements Action {
  public readonly type = AdminRequestsOverviewActionTypes.ListLoaded;
}

export class RequestOverviewLinksLoaded implements Action {
  public readonly type =
    AdminRequestsOverviewActionTypes.RequestOverviewLinksLoaded;
  public readonly payload: any = null;

  constructor(payload: any) {
    this.payload = payload;
  }
}

export class GetTechnicianUrl implements Action {
  public readonly type = AdminRequestsOverviewActionTypes.GetTechnicianUrl;
  public readonly payload: any = null;

  constructor(payload: any) {
    this.payload = payload;
  }
}

export class FailedToLoadRequestsOverviewLinks implements Action {
  public readonly type =
    AdminRequestsOverviewActionTypes.FailedToLoadRequestsOverviewLinks;
}

export const ResetContractStatus = createAction(
  '[AdminRequestsOverview] ResetContractStatus',
  props<{ contract: Contract }>()
);


export type AdminRequestsOverviewAction =
  | LoadRequestsOverviewLinks
  | FailedToLoadRequestsOverviewLinks
  | RequestOverviewLinksLoaded
  | MrosListLoaded
  | LoadAgenciesList
  | AgenciesListLoaded
  | FailedToLoadList
  | GetTechnicianUrl;
