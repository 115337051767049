<div class="content-wrapper">
    <entity-nav [activeItem]="activeItem" [menuItems]="menuItems" (selectedItem)="handleEntityNavigationChange($event)">
    </entity-nav>

    <h1 class="h1">
        <strong>
            {{ 'Manage ' + (managingRole | humanReadableRole) + ' Requests' | translate }}
        </strong>
    </h1>
    <div class="legend" *ngIf="isMroSelected()">
        <div class="legend-item guidelines">
            <div class="box closed-offer"></div>
            <div class="description">Job Opening that is closed (technicians cannot apply neither being invited).</div>
        </div>
        <div class="legend-item guidelines">
            <div class="box finished-contract"></div>
            <div class="description">Contract that is already finished (the displayed end date is real).</div>
        </div>
        <div class="legend-item guidelines">
            <div class="box expired-contract"></div>
            <div class="description">
                <p>Job Opening that contains some expired contract.</p>
                <p>Contract that is expired (it has not been finished, the displayed end date is just for guidance).</p>
            </div>
        </div>
    </div>
    <div class="overview-actions-container">
        <div class="form-group-lg mro-company-filter">
            <label for="company-name">Name*: </label>
            @switch (activeItem) { @case (MENU_ITEMS_ENUM.MROS) {
                @if (entityRetrieverLinks) {
                <mro-selector
                    (selectedMroUuidChange)="handleSelectedEntityChange($event)"
                    [loadMros]="getMroListRetriever()"
                />
                }
            } @case (MENU_ITEMS_ENUM.TECHNICIANS) {
            <technician-paginated-selector (selectedTechnicianUuidChange)="handleSelectedEntityChange($event)">
            </technician-paginated-selector>
            } @default {
            <typeahead-filter
                id="company-name"
                [placeholder]="'Search by name' | translate"
                [initialUuid]="selectedValueUuid"
                [entityList]="entityList"
                (onSelect)="handleSelectedEntityChange($event)"
                (onBlur)="onBlurHandler($event)"
            >
            </typeahead-filter>
            } }
        </div>
        <div class="form-group dropdown">
            <label for="offer-type">Job Opening Type: </label>
            <select
                id="offer-type"
                class="custom-select"
                name="offer-type"
                [(ngModel)]="selectedOfferType"
                (change)="updateOfferType()"
            >
                <option *ngFor="let offerType of filteredOfferTypes" value="{{ offerType.value }}">
                    {{ offerType.name | translate }}
                </option>
            </select>
        </div>
        <ng-container *ngIf="isMroSelected()">
            <div class="form-group dropdown">
                <label for="role">Acting as Aviation Company: </label>
                <select
                    id="role"
                    class="custom-select"
                    name="role"
                    [(ngModel)]="selectedMroRole"
                    (change)="handleMroRoleChange()"
                >
                    <option *ngFor="let role of MRO_ROLES" value="{{ role.serverName }}">
                        {{ role.name }}
                    </option>
                </select>
            </div>
            <div class="form-group create-offer-button-wrapper">
                <general-button
                    [isDisabled]="!(activeItem === menuItemsEnum.MROS && selectedValueUuid)"
                    (onClick)="openCreateJobOfferPage()"
                    backgroundStyle="outline"
                    text="{{ 'REQUEST_LISTING.CREATE_TEMPORARY_JOB_OPENING' | translate }}"
                ></general-button>
                <general-button
                    *ngIf="canOpenCreatePermanentOfferPage()"
                    [isDisabled]="!(activeItem === menuItemsEnum.MROS && selectedValueUuid)"
                    (onClick)="openCreatePermanentOfferPage()"
                    backgroundStyle="outline"
                    text="CREATE PERMANENT JOB OPENING"
                ></general-button>
                <general-button
                    *ngIf="canOpenCreatePackageOfferPage()"
                    [isDisabled]="!(activeItem === menuItemsEnum.MROS && selectedValueUuid)"
                    (onClick)="openCreatePackageOfferPage()"
                    backgroundStyle="outline"
                    text="CREATE FIXED-PRICE JOB OPENING"
                ></general-button>
            </div>
        </ng-container>
    </div>

    <router-outlet></router-outlet>
</div>
